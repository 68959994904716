import React, { useCallback, useEffect, useState } from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import {
  getLocalStorage,
  getSessionStorage,
  getStatusByAnswer,
  removeLocalStorage,
  removeSessionStorage,
  setDataLocalStorage,
  setDataSessionStorage,
} from 'share/utils';
import { useCheckLogin, useMutationCustom, useUpdateMeClient } from 'hooks';
import {
  CreateSurveyWithTokenMutation,
  CreateSurveyWithTokenMutationVariables,
  CreateSurveyResultMutation,
  CreateSurveyResultMutationVariables,
  SubmitFormDataMutation,
  SubmitFormDataMutationVariables,
  FormStatus,
  CreateDynamicFormDataInput,
} from 'types.d';
import {
  CREATE_ANONYMOUS_SURVEY_RESULT,
  CREATE_SURVEY_RESULT,
  CREATE_SURVEY_WITH_TOKEN,
  SUBMIT_FORM_DATA,
} from 'gql';
import { KEY_LOCAL_ASSIGNED_SURVEYS, KEY_SESSION_ORGANIZATION_ID } from 'CONST';
import { SurveyFilledFromChatbot } from 'modules/google_tag_manager';

type Props = {
  setAssignedSurvey: any;
  assignedSurvey: any;
  messages: any;
  setMessages: any;
  setSurveyInitiated: any;
  selectedSurvey: any;
  scrollToBottom?: () => void;
  dynamicSurvey: string;
};

export const FillSurvey = (props: Props) => {
  const { meClient } = useUpdateMeClient();

  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  const [userResponses, setUserResponses] = useState({});

  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

  const isLogin = useCheckLogin();

  const survey =
    props.selectedSurvey != null
      ? new Model({
          ...props.selectedSurvey,
          elements: [props.selectedSurvey?.elements[currentElementIndex]],
        })
      : new Model({});

  const [createSurvey] = useMutationCustom<
    CreateSurveyResultMutation,
    CreateSurveyResultMutationVariables
  >({
    api: CREATE_SURVEY_RESULT,
    textSuccess: 'Thank you for completing survey',
  });

  const [createTokenSurvey] = useMutationCustom<
    CreateSurveyWithTokenMutation,
    CreateSurveyWithTokenMutationVariables
  >({
    api: CREATE_SURVEY_WITH_TOKEN,
    textSuccess: 'Thank you for completing survey',
    callbackSuccess: data => {
      setIsSurveyCompleted(true);
      props.setAssignedSurvey(false);
      if (isLogin) {
        var assignedSurveys = JSON.parse(
          getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
        );
      } else {
        var assignedSurveys = JSON.parse(
          getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
        );
      }
      if (assignedSurveys != null && assignedSurveys.length > 0) {
        if (isLogin) {
          setDataLocalStorage(
            KEY_LOCAL_ASSIGNED_SURVEYS,
            JSON.stringify(
              assignedSurveys.filter((survey: any) => {
                if (props.selectedSurvey.token !== survey.token) return survey;
              }),
            ),
          );
        } else {
          setDataSessionStorage(
            KEY_LOCAL_ASSIGNED_SURVEYS,
            JSON.stringify(
              assignedSurveys.filter((survey: any) => {
                if (props.selectedSurvey.token !== survey.token) return survey;
              }),
            ),
          );
        }
      } else {
        isLogin
          ? removeLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)
          : removeSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS);
      }
    },
  });

  const [
    createAnonymousSurvey,
    { loading: anonymousloading },
  ] = useMutationCustom<
    CreateSurveyResultMutation,
    CreateSurveyResultMutationVariables
  >({
    api: CREATE_ANONYMOUS_SURVEY_RESULT,
    textSuccess: 'Thank you for completing survey',
  });

  const [createDynamicFormData] = useMutationCustom<
    SubmitFormDataMutation,
    SubmitFormDataMutationVariables
  >({
    api: SUBMIT_FORM_DATA,
    textSuccess: 'Thank you for completing survey',
  });

  const handleComplete = useCallback(
    sender => {
      const nextIndex = currentElementIndex + 1;
      const updatedResponses = {
        ...userResponses,
        ...sender.data,
      };

      const keys = Object.keys(updatedResponses);
      const latestKey = keys[keys.length - 1];

      showResponse(latestKey, updatedResponses);
      setUserResponses(updatedResponses);

      if (nextIndex < props.selectedSurvey.elements.length) {
        setCurrentElementIndex(nextIndex);
      } else {
        setIsSurveyCompleted(true);

        if (props?.selectedSurvey?.dynamicForm) {
          let params: CreateDynamicFormDataInput = {
            status: FormStatus.New,
            formJson: JSON.stringify(props.dynamicSurvey),
            answersJson: JSON.stringify(survey.data),
          };

          const { _id, organizationId } = meClient;
          const orgId = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;

          if (_id) params['userId'] = _id;

          if (organizationId) params['organization'] = organizationId;
          else if (orgId) params['organization'] = orgId;

          createDynamicFormData({
            variables: {
              params: params,
            },
          });
        } else if (props.assignedSurvey && props.selectedSurvey.token != null) {
          createTokenSurvey({
            variables: {
              params: {
                data: survey.data,
                survey: survey.surveyId,
                completedStatus: getStatusByAnswer(survey),
                token: props.selectedSurvey.token,
                surveyVersion: props.selectedSurvey.surveyVersion,
              },
            },
          });
          SurveyFilledFromChatbot('assigned');
        } else {
          const createSurveyFunction = isLogin
            ? createSurvey
            : createAnonymousSurvey;

          const surveyParams = {
            variables: {
              params: {
                data: survey.data,
                survey: survey.surveyId,
                completedStatus: getStatusByAnswer(survey),
              },
            },
          };

          createSurveyFunction(surveyParams);
          SurveyFilledFromChatbot('non assigned');
        }
      }
    },
    [
      currentElementIndex,
      userResponses,
      props.selectedSurvey,
      props.setSurveyInitiated,
    ],
  );

  survey?.onComplete.add(handleComplete);

  useEffect(() => {
    if (isSurveyCompleted) {
      props.setMessages([
        ...props.messages,
        {
          text:
            'Thank you for filling the survey. Your responses have been recorded!',
          type: 'bot',
        },
      ]);
      props.setSurveyInitiated(null);
      //props.selectedSurvey(null)
    }
  }, [isSurveyCompleted, props]);

  useEffect(() => {
    // Check the visibleIf condition and move to the next question if it fails
    const currentElement = survey?.getQuestionByName(
      props.selectedSurvey?.elements[currentElementIndex].name,
    );
    if (currentElement && !currentElement.visible) {
      const nextIndex = currentElementIndex + 1;
      if (nextIndex < props.selectedSurvey?.elements.length) {
        setCurrentElementIndex(nextIndex);
        survey.elements = [props.selectedSurvey?.elements[nextIndex]];
      } else {
        setIsSurveyCompleted(true);
      }
    }
  }, [currentElementIndex, props.selectedSurvey, survey]);

  useEffect(() => {
    // Function to update the button text
    const updateButtonText = () => {
      // Check if it's the last question
      const isLastQuestion =
        currentElementIndex === props.selectedSurvey.elements.length - 1;
      survey.completeText = isLastQuestion ? 'Complete' : 'Next'; // Change the button text based on the question index
      survey.render(); // Re-render the survey to apply the text change
    };

    survey.onAfterRenderQuestion.add((sender, options) => {
      updateButtonText();
    });

    // Initial button text update for the first question
    updateButtonText();

    // Cleanup function to remove the event listener
    return () => {
      survey.onAfterRenderQuestion.clear(); // Clear the event listener when the component is unmounted or re-rendered
    };
  }, [currentElementIndex, props.selectedSurvey.elements.length, survey]);

  useEffect(() => {
    // Function to scroll to the bottom of the page
    const scrollToBottom = () => {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth', // Optional: defines a smooth scroll
      });
    };

    // Call the scroll function after the question is rendered
    if (props?.scrollToBottom) props?.scrollToBottom();
  }, [currentElementIndex]); // Depend on currentElementIndex to trigger scroll on change

  const showResponse = (latestKey: any, updatedResponses: any) => {
    var response = updatedResponses[latestKey];
    const responseType = typeof updatedResponses[latestKey];

    if (responseType == 'boolean') {
      response == true ? (response = 'Yes') : (response = 'No');
    } else if (responseType == 'object') {
      if (!Array.isArray(response)) {
        var temp = '';
        Object.keys(response).map(item => {
          const answer = response[item].Answer ? 'Yes' : 'No';
          temp += item + ' ' + answer + ' - \n'; // Add "\n" for a new line
        });
        response = temp;
      }
    }

    if (props?.selectedSurvey?.dynamicForm !== true) {
      props.setMessages([
        ...props.messages,
        {
          text: props.selectedSurvey.elements[currentElementIndex].title,
          type: 'bot',
        },
        {
          text: response ? response.toString() : 'No response recorded',
          type: 'user',
        },
      ]);
    }
  };

  return (
    <div>
      {!isSurveyCompleted && survey != null && (
        <Survey model={survey} data={userResponses} />
      )}
    </div>
  );
};
